.profilepic{
    float: left;
    padding:20px;
    width: 200px;
    height: 200px;
}

.flipitemback{
    text-align: left;
    border: 1px solid grey;
    padding:15;
    margin: 15;
}