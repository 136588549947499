.Javascript{
    font-size: 30px;
    color: yellow;
    background-color: black;
    margin: 5px;
}

.Html{
    color:orange;
    font-size: 28px;
    margin: 5px;
}

.Css{
    color:blue;
    font-size:28px;
    margin: 5px;
}

h4{
    text-align: center;
    font-size: x-large;
}
.Nodejs{
    font-size: 45px;
    color: green;
    margin: 5px;
}
.Typescript{
    font-size: 28px;
    color: #2f74c0;
    background-color: white;
    margin: 5px;
}